<template>
  <div class="jituan index">
    <Header></Header>
    <div class="top">
      <div class="title">共赢未来</div>
      <!-- <div class="more">
        专注于国内公共安全信息发展，打造专业的智慧产业服务平台
      </div> -->
      <div class="jianjie">简介</div>
      <div class="text">
        始创于2001年的成都汉康信息产业有限公司，总部坐落在美丽的“天府之国”成都高新技术产业区。从ICT行业起步，经过23年的发展，现已成为ICT领域多元化投资控股公司，致力于成为中国领先的智慧城市、大数据、区块链、健康管理、产业园等投资、建设、运营于一体的高新技术企业集团。
        <br />
        <br />
        在全国拥有分子公司超过10家，目前在成都、北京、上海、安徽、武汉、南京、贵阳设有独立控股公司和研究院。基于对经济与企业的深刻理解，总结并形成了颇具特色的投资理念与管理体系；通过前瞻性布局、清晰的投资策略，打造了一批有影响力的优秀科技企业；同时，高度重视并充分发挥人才的作用，发现并培养领军人物，为员工创造事业舞台，激发企业的发展活力。
      </div>
      <!-- <div class="nav-box" ref="navbox">
        <div class="nav" ref="nav">
          <div
            class="son"
            v-for="(item, index) in list"
            :class="{
              active: type == item.type,
              'nav-active': item.activebool,
            }"
            :key="index"
            @click="goto(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div> -->
    </div>
    <div class="touzi">
      <div class="title">对外投资</div>
      <div class="jieshao">助力多个行业产业发展，为高新企业赋能</div>
      <div class="box">
        <div class="shang">
          <div class="shuiwu">
            <img src="../../assets/imgs/guanyu/icon (7).png" alt="" />
            <div class="biaoti">智慧水务</div>
            <!-- <div class="text">
              一批有影响力的优秀科技企业；同时，高度重视并充分发挥人才的作用，发现并培养领军
            </div> -->
          </div>
          <div class="gongshui">
            <img src="../../assets/imgs/guanyu/icon (4).png" alt="" />
            <div class="biaoti">二次供水</div>
            <!-- <div class="text">
              一批有影响力的优秀科技企业；同时，高度重视并充分发挥人才的作用，发现并培养领军
            </div> -->
          </div>
          <div class="shang-right">
            <div class="ict">
              <img src="../../assets/imgs/guanyu/icon (2).png" alt="" />
              <div class="biaoti">ICT</div>
              <!-- <div class="text">
                一批有影响力的优秀科技企业；同时，高度重视并充分发挥人才的作用，发现并培养领军
              </div> -->
            </div>
            <div class="qukuailian">
              <img src="../../assets/imgs/guanyu/icon (5).png" alt="" />
              <div class="biaoti">区块链</div>
              <!-- <div class="text">
                一批有影响力的优秀科技企业；同时，高度重视并充分发挥人才的作用，发现并培养领军
              </div> -->
            </div>
          </div>
        </div>
        <div class="xia">
          <div class="dashuju">
            <img src="../../assets/imgs/guanyu/icon (3).png" alt="" />
            <div class="biaoti">大数据</div>
            <!-- <div class="text">
              一批有影响力的优秀科技企业；同时，高度重视并充分发挥人才的作用，发现并培养领军
            </div> -->
          </div>
          <div class="yuanyuzhou">
            <img src="../../assets/imgs/guanyu/icon (6).png" alt="" />
            <div class="biaoti">元宇宙</div>
            <!-- <div class="text">
              一批有影响力的优秀科技企业；同时，高度重视并充分发挥人才的作用，发现并培养领军
            </div> -->
          </div>
          <div class="jiuyu">
            <img src="../../assets/imgs/guanyu/icon (1).png" alt="" />
            <div class="biaoti">众二酒业</div>
            <!-- <div class="text">
              一批有影响力的优秀科技企业；同时，高度重视并充分发挥人才的作用，发现并培养领军
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="jiagou">
      <div class="jiegou">组织架构</div>
    </div> -->
    <!-- <div class="song">
      <div class="mingzi">汉康之歌</div>
      <div class="ci">词：刘金彪</div>
      <div class="qu">曲：韩 毅</div>
      <div class="chang">演唱：胡 琳</div>
      <div class="ti">
        太阳神鸟展开了翅膀，<br />
        在无垠的河汉里翱翔。<br />
        都江堰凝固千年丰碑，<br />
        仿佛是五达为康的乐章。<br />
      </div>
      <div class="er">
        你用火眼金睛，洞察山川的异样；<br />
        你用大数据联结，中华城乡；<br />
        你用真情感知世界，焕发无穷能量；<br />
        汉康云是你的，你的智库。<br />
        啊，成都汉康，<br />
        噢，巴蜀汉康，<br />
        噢，华夏汉康，<br />
        噢，书写辉煌！<br />
      </div>
      <div class="san">
        不忘初心，暖心房。<br />
        我爱汉康，好自强……<br />
      </div>
      <div class="si">
        啊，<br />
        幸福汉康，我们的家，<br />
        无极之路，闪金光……<br />
      </div>
      <audio
        class="audio"
        src="../../assets/video/hksong.mp3"
        controls
        muted
      ></audio>
    </div> -->

    <Footer></Footer>

    <div class="toback" ref="toback">
      <div class="toback-lianxi">
        <div class="toback-ph">
          <div class="toback-sons">
            <div class="toback-barss">
              <div class="toback-top">咨询电话</div>
              <div class="toback-bottom">133-3098-1239</div>
            </div>
            <div class="toback-barss">
              <div class="toback-top">技术支持</div>
              <div class="toback-bottom">189-8005-3442</div>
            </div>
            <div class="toback-barss">
              <div class="toback-top">售后电话</div>
              <div class="toback-bottom">400-8878-352</div>
            </div>
          </div>
        </div>
        <div class="toback-wx">
          <div class="toback-son">
            <img src="../../assets/imgs/home/gongzonghao.png" alt />
            <div class="toback-tip">(成都汉康微信)</div>
          </div>
        </div>
      </div>
      <div class="toback-up" @click="up"></div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
export default {
  components: {
    Header,

    Footer,
  },
  data() {
    return {
      type: 0,
      list: [
        { name: "对外投资", type: 0, activebool: false, num: 900 },
        { name: "组织架构", type: 1, activebool: false, num: 1600 },
        { name: "汉康之歌", type: 2, activebool: false, num: 2222 },
      ],
      //   touzi: [{ src: require("../../assets/imgs/guanyu/touzi (1).png"),
      //    }],
      timer: null,
      arr: [],
      xidingarr: [],

      activebool: false,
    };
  },
  methods: {
    up() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      let sudu = 40;
      if (top >= 0 && top < 1000) {
        sudu = 40;
      } else if (top >= 1000 && top < 2000) {
        sudu = 60;
      } else if (top >= 2000 && top < 3000) {
        sudu = 100;
      } else if (top >= 3000 && top < 4000) {
        sudu = 140;
      } else {
        sudu = 200;
      }
      this.timer = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            sudu;

        if (top <= 0) {
          clearInterval(this.timer);
        }
      }, 10);

      // document.body.scrollTop = 0;
    },
    scroll() {
      let scroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.arr.push(scroll);
      //console.log("scroll :>> ", scroll);
      if (this.arr.length > 2) {
        let a = [this.arr[this.arr.length - 1]];
        let b = [this.arr[this.arr.length - 2]];

        if (a - b >= 0) {
          // console.log("往下滑");

          if (scroll >= 470) {
            this.show = true;
          } else {
            this.show = false;
          }
        } else {
          // console.log("往上滑");
        }
        if (scroll > 120) {
          this.$refs.toback.style.opacity = "1";
        } else {
          this.$refs.toback.style.opacity = "0";
        }
      }
    },
    xidingscroll() {
      let scroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      this.xidingarr.push(scroll);
      //console.log("scroll :>> ", scroll);
      if (this.xidingarr.length > 2) {
        let a = [this.xidingarr[this.xidingarr.length - 1]];
        let b = [this.xidingarr[this.xidingarr.length - 2]];

        if (a - b >= 0) {
          // console.log("往下滑");
          if (scroll >= 900) {
            this.$refs.navbox.style.backgroundColor = "#fff";
            this.$refs.navbox.style.position = "fixed";
            this.$refs.navbox.style.top = "0";
            this.$refs.navbox.style.left = "0";
            this.$refs.navbox.style.zIndex = "10";
            // this.$refs.nav.style.backgroundColor = "rgba(22, 100, 255, 1)";
          }
        } else {
          // console.log("往上滑");
          if (scroll <= 900) {
            this.$refs.navbox.style.backgroundColor = "";
            this.$refs.navbox.style.position = "absolute";
            this.$refs.navbox.style.top = "905px";
          }
        }

        if (scroll < 900) {
          for (const item of this.list) {
            item.activebool = false;
          }
        }
        if (scroll >= 900 && scroll <= 1530) {
          for (const item of this.list) {
            item.activebool = false;
          }
          this.list[0].activebool = true;
          this.type = 0;
        } else if (scroll >= 1600 && scroll <= 2150) {
          for (const item of this.list) {
            item.activebool = false;
          }
          this.list[1].activebool = true;
          this.type = 1;
        } else if (scroll >= 2222) {
          for (const item of this.list) {
            item.activebool = false;
          }
          this.list[2].activebool = true;
          this.type = 2;
        }
      }
    },
    goto(item) {
      this.type = item.type;
      document.body.scrollTop = item.num;
    },
  },
  mounted() {
    document.body.scrollTop = 0;
    if (document.body.scrollTop >= 900) {
      this.$refs.navbox.style.backgroundColor = "#fff";
      this.$refs.navbox.style.position = "fixed";
      this.$refs.navbox.style.top = "0";
      this.$refs.navbox.style.left = "0";
      this.$refs.navbox.style.zIndex = "10";
    }
    window.addEventListener("scroll", this.scroll, true);
    // window.addEventListener("scroll", this.xidingscroll, true);
  },
  beforeDestroy() {
    // this.time=null
    window.removeEventListener("scroll", this.scroll, true);
    // window.removeEventListener("scroll", this.xidingscroll, true);
  },
};
</script>

<style lang="scss" scoped>
.nav-active {
  background-color: rgba(22, 100, 255, 1) !important;
  color: #ffffff !important;
}

.jituan {
  width: 1920px;
  height: 100%;

  .top {
    width: 100%;
    height: 928px;
    background: url("../../assets/imgs/guanyu/jituanbg.png");
    background-size: 100% 100%;
    position: relative;
    padding-left: 344px;

    .title {
      font-size: 60px;
      font-family: Alibaba PuHuiTi !important;
      font-weight: 700;
      line-height: 84px;
      color: #ffffff;
      position: absolute;
      top: 214px;
    }

    .more {
      font-size: 16px;
      font-family: Alibaba PuHuiTi !important;
      font-weight: normal;
      line-height: 22px;
      color: #ffffff;
      position: absolute;
      top: 305px;
    }

    .jianjie {
      font-size: 20px;
      font-family: Alibaba PuHuiTi Xi !important;
      font-weight: normal;
      line-height: 28px;
      color: #ffffff;
      position: absolute;

      top: 515px;
    }

    .text {
      width: 617px;
      font-size: 12px;
      font-family: Alibaba PuHuiTi Xi !important;
      font-weight: normal;
      line-height: 20px;
      color: #ffffff;
      position: absolute;
      top: 590px;
      top: 563px;
    }

    .nav-box {
      width: 100%;
      height: 52px;
      // background-color: red;
      position: absolute;
      //bottom: -26px;
      top: 905px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .nav {
        width: 1140px;
        height: 52px;
        display: flex;
        // position: absolute;
        // bottom: -26px;
        // left: 334px;
        background-color: #ffffff;

        // align-items: center;
        // justify-content: center;
        .son {
          width: 380px;
          height: 52px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: Alibaba PuHuiTi !important;
          font-weight: normal;
          line-height: 30px;
          color: #888888;
          cursor: pointer;
        }

        .son:hover {
          color: rgba(22, 100, 255, 1);
        }

        .active {
          // color: rgba(22, 100, 255, 1);
        }
      }
    }
  }

  .touzi {
    background-color: rgba(243, 243, 243, 1);
    width: 1920px;
    height: 786px;
    padding: 0 260px;
    padding-top: 86px;

    .title {
      width: 100%;
      text-align: center;
      font-size: 36px;
      font-family: Alibaba PuHuiTi Xi !important;
      font-weight: normal;
      line-height: 24px;
      color: #333333;
    }

    .jieshao {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-family: Alibaba PuHuiTi Xi !important;
      font-weight: normal;
      line-height: 22px;
      color: #999999;
      margin-top: 16px;
    }

    .box {
      width: 100%;
      margin-top: 40px;

      img {
        width: 40px;
        height: 40px;
      }

      .biaoti {
        font-size: 24px;
        font-family: Alibaba PuHuiTi Xi !important;
        font-weight: normal;
        color: #ffffff;
      }

      .text {
        width: 285px;
        font-size: 12px;
        font-family: Alibaba PuHuiTi Xi !important;
        font-weight: normal;
        line-height: 20px;
        color: #ffffff;
      }

      .shang {
        width: 100%;
        height: 348px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        .shuiwu {
          width: 600px;
          height: 100%;
          background: url("../../assets/imgs/guanyu/touzi (1).png");
          background-size: 100% 100%;

          position: relative;

          img {
            position: absolute;
            left: 34px;
            bottom: 63px;
          }

          .biaoti {
            position: absolute;
            left: 34px;
            bottom: 19px;
          }

          .text {
            position: absolute;
            left: 34px;
            bottom: 16px;
          }
        }

        .gongshui {
          width: 374px;
          height: 100%;
          background: url("../../assets/imgs/guanyu/touzi (2).png");
          background-size: 100% 100%;
          position: relative;

          img {
            position: absolute;
            left: 34px;
            bottom: 63px;
          }

          .biaoti {
            position: absolute;
            left: 34px;
            bottom: 19px;
          }

          .text {
            position: absolute;
            left: 34px;
            bottom: 16px;
          }
        }

        .shang-right {
          width: 374px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .ict {
            width: 100%;
            height: 164px;
            background: url("../../assets/imgs/guanyu/touzi (3).png");
            background-size: 100% 100%;
            position: relative;

            img {
              position: absolute;
              left: 34px;
              top: 67px;
            }

            .biaoti {
              position: absolute;
              left: 34px;
              top: 113px;
            }

            .text {
              position: absolute;
              left: 34px;
              top: 111px;
            }
          }

          .qukuailian {
            width: 100%;
            height: 164px;
            background: url("../../assets/imgs/guanyu/touzi (4).png");
            background-size: 100% 100%;
            position: relative;

            img {
              position: absolute;
              left: 34px;
              top: 67px;
            }

            .biaoti {
              position: absolute;
              left: 34px;
              top: 113px;
            }

            .text {
              position: absolute;
              left: 34px;
              top: 111px;
            }
          }
        }
      }

      .xia {
        width: 100%;
        height: 164px;
        display: flex;
        justify-content: space-between;

        .dashuju {
          width: 374px;
          height: 164px;
          background: url("../../assets/imgs/guanyu/touzi (5).png");
          background-size: 100% 100%;
          position: relative;

          img {
            position: absolute;
            left: 34px;
            top: 67px;
          }

          .biaoti {
            position: absolute;
            left: 34px;
            top: 113px;
          }

          .text {
            position: absolute;
            left: 34px;
            top: 111px;
          }
        }

        .yuanyuzhou {
          width: 374px;
          height: 164px;
          background: url("../../assets/imgs/guanyu/touzi (6).png");
          background-size: 100% 100%;
          position: relative;

          img {
            position: absolute;
            left: 34px;
            top: 67px;
          }

          .biaoti {
            position: absolute;
            left: 34px;
            top: 113px;
          }

          .text {
            position: absolute;
            left: 34px;
            top: 111px;
          }
        }

        .jiuyu {
          width: 600px;
          height: 164px;
          background: url("../../assets/imgs/guanyu/touzi (7).png");
          background-size: 100% 100%;
          position: relative;

          img {
            position: absolute;
            left: 34px;
            top: 67px;
          }

          .biaoti {
            position: absolute;
            left: 34px;
            top: 113px;
          }

          .text {
            position: absolute;
            left: 34px;
            top: 111px;
          }
        }
      }
    }
  }

  .jiagou {
    width: 1920px;
    height: 560px;
    background: url("../../assets/imgs/guanyu/zuzhi.png");
    background-size: 100% 100%;
    position: relative;
    top: 1px;

    .jiegou {
      width: 100%;
      text-align: center;
      font-size: 36px;
      font-family: Alibaba PuHuiTi Xi !important;
      font-weight: normal;
      line-height: 24px;
      color: #ffffff;
      position: relative;
      top: 60px;
    }
  }

  .song {
    width: 1920px;
    height: 777px;
    background: url("../../assets/imgs/guanyu/song.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;

    .mingzi {
      font-size: 36px;
      font-family: Alibaba PuHuiTi;
      font-weight: normal;
      line-height: 24px;
      color: #333333;
    }

    .ci {
      margin-top: 40px;
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: normal;
      line-height: 24px;
      color: #888888;
    }

    .qu {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: normal;
      line-height: 24px;
      color: #888888;
    }

    .chang {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: normal;
      line-height: 24px;
      color: #888888;
    }

    .ti {
      font-size: 16px;
      font-family: Alibaba PuHuiTi;
      font-weight: normal;
      line-height: 22px;
      color: #333333;

      text-align: center;
      margin-top: 20px;
    }

    .er {
      font-size: 16px;
      font-family: Alibaba PuHuiTi;
      font-weight: normal;
      line-height: 22px;
      color: #333333;

      text-align: center;
      margin-top: 14px;
    }

    .san {
      font-size: 16px;
      font-family: Alibaba PuHuiTi;
      font-weight: normal;
      line-height: 22px;
      color: #333333;

      text-align: center;
      margin-top: 14px;
    }

    .si {
      font-size: 16px;
      font-family: Alibaba PuHuiTi;
      font-weight: normal;
      line-height: 22px;
      color: #333333;

      text-align: center;
      margin-top: 14px;
    }

    .audio {
      margin-top: 40px;
      width: 560px;
      height: 50px;
      //   background-color: #fff;
    }
  }
}
</style>
