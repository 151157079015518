<template>
  <div class="footer">
    <div class="body">
      <div class="right">
        <img src="../assets/imgs/home/gongzonghao.png" alt />
        <div class="tips">(成都汉康微信公众号)</div>

        <div class="tongxun">
          <div class="weixi">
            <div class="img">
              <!-- <img src="../assets/imgs/home/icon (2).png" alt /> -->
            </div>
          </div>
          <div class="qq">
            <div class="img">
              <!-- <img src="../assets/imgs/home/icon (2).png" alt /> -->
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <div class="title" v-for="(item, index) in list" :key="index">
          {{ item.name }}
          <em></em>
          <div class="more">
            <div
              class="son"
              v-for="(val, i) in item.more"
              :key="i"
              @click="goto(val)"
            >
              {{ val.name }}
            </div>
          </div>
        </div>
      </div>
      
       <div class="left">
        <img
          src="../assets/imgs/home/fttt.png"
          alt
          class="ft-img"
          @click="gotohk"
        />
        <div class="lianxi">
          <div class="dizhi">
            地址：成都市武侯区二环路南三段如意人家商务楼三楼
          </div>
          <div class="dianhua">电话：028-86660094</div>
          <div key="youxiang">邮箱：2853750085@qq.com</div>
        </div>
        <!-- <div class="tongxun">
          <div class="weixi">
            <div class="img">
              <img src="../assets/imgs/home/icon (2).png" alt />
            </div>
          </div>
          <div class="qq">
            <div class="img">
              <img src="../assets/imgs/home/icon (2).png" alt />
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="from">
      Copyright 2020 HanKang
      <a href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备14001042号</a>

      <div class="link">
        <div class="link-son">友情链接</div>
        <div class="link-son" v-for="(item, index) in link" :key="index">
          <a :href="item.src" target="blank"> {{ item.name }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: "产品中心",
          more: [
            { name: "智慧水务产品", path: "/shuiwu" },
            { name: "城市安全产品", path: "/chengshianquan" },
            { name: "智慧城市产品", path: "/zhihui" },
            { name: "智能设备产品", path: "/shebei" },
            { name: "智慧工具产品", path: "/gongju" },
          ],
        },
        {
          name: "方案中心",
          more: [
            { name: "智慧水务解决方案", path: "/solutionCenter/shuiwu" },
            { name: "城市安全解决方案", path: "/solutionCenter/anquan" },

            { name: "智慧城市解决方案", path: "/solutionCenter/chengshi" },
            { name: "智慧政务解决方案", path: "/solutionCenter/zhengwu" },
          ],
        },
        {
          name: "成功案例",
          more: [
            { name: "智慧水务项目", path: "/chenggong" },
            { name: "城市安全项目", path: "/chenggong" },
            { name: "智慧城市项目", path: "/chenggong" },
            { name: "智慧政务项目", path: "/chenggong" },
          ],
        },
        {
          name: "关于汉康",
          more: [
            { name: "成都汉康", path: "/about" },
            // { name: "■■■■", path: "/about/jituanhk" },
            { name: "专家团队", path: "/about/zhuanjian" },
            { name: "企业文化", path: "/about/wenhua" },
            { name: "汉康之歌", path: "/about/song" },
          ],
        },
      ],
      link: [
        { name: "四川省水利厅", src: "http://slt.sc.gov.cn/" },
        { name: "国家信息中心", src: "http://www.sic.gov.cn/" },
        { name: "成都市水务局", src: "http://cdwater.chengdu.gov.cn/" },
        { name: "中国水生态大数据中心", src: "http://nationwater.cn/" },
        { name: "感知城市数据科学研究院", src: "http://www.updatahub.org/" },
        // { name: "川酒集团", src: "https://www.chuanjiujituan.com/" },
        { name: "成都环境集团", src: "http://www.cdenvironment.com/" },
        { name: "成都交投集团", src: "https://www.cdccic.com/" },
      ],
    };
  },
  methods: {
    goto(val) {
      //  console.log("val :>> ", val);
      this.$router.push(val.path);
    },
    gotohk() {
      this.$router.push("/about");
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  margin: 0 auto;
  width: 1920px;
  height: 574px;
  background-color: rgba(16, 16, 16, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  // position: fixed;
  // left: 0;
  // bottom: 0;
  .body {
    width: 1400px;
    height: 350px;
    border-bottom: 1px solid #333;

    display: flex;

    .left {
      width: 32%;
      height: 100%;

      .ft-img {
        width: 248px;
        height: 52px;
        cursor: pointer;
      }

      .lianxi {
        margin-top: 40px;
        color: rgba(136, 136, 136, 1);
        font-size: 16px;
        font-family: Alibaba PuHuiTi Xi;

        div {
          margin-top: 20px;
        }
      }

      // .tongxun {
      //   margin-top: 30px;
      //   display: flex;

      //   .weixi {
      //     width: 42px;
      //     height: 42px;
      //     background-image: url("../assets/imgs/home/lianxi.png");
      //     background-size: 100% 100%;
      //     cursor: pointer;
      //     position: relative;

      //     .img {
      //       width: 76px;
      //       height: 76px;
      //       background-image: url("../assets/imgs/home/gongzonghao.png");
      //       background-size: 100% 100%;
      //       position: absolute;
      //       bottom: -90px;
      //       left: -20px;
      //       display: none;

      //       img {
      //         position: absolute;
      //         top: -10px;
      //         left: 34px;
      //       }
      //     }
      //   }

      //   .qq {
      //     width: 42px;
      //     height: 42px;
      //     margin-left: 20px;
      //     background-image: url("../assets/imgs/home/qq.png");
      //     background-size: 100% 100%;
      //     cursor: pointer;
      //     position: relative;

      //     .img {
      //       width: 76px;
      //       height: 76px;
      //       background-image: url("../assets/imgs/home/gongzonghao.png");
      //       background-size: 100% 100%;
      //       position: absolute;
      //       bottom: -90px;
      //       left: -20px;
      //       display: none;

      //       img {
      //         position: absolute;
      //         top: -10px;
      //         left: 34px;
      //       }
      //     }
      //   }

      //   .weixi:hover {
      //     background-image: url("../assets/imgs/home/lianxiactive.png");
      //   }

      //   .weixi:hover .img {
      //     display: block;
      //   }

      //   .qq:hover {
      //     background-image: url("../assets/imgs/home/qqactive.png");
      //   }

      //   .qq:hover .img {
      //     display: block;
      //   }
      // }
    }

    .center {
      height: 100%;
      width: 60%;
      display: flex;

      .title {
        width: 25%;
        height: 100%;
        display: flex;
        justify-content: center;

        font-family: Alibaba PuHuiTi Xi;
        position: relative;

        em {
          width: 2px;
          height: 16px;
          position: absolute;
          left: 54px;
          top: 4px;
          background-color: #fff;
        }

        .more {
          position: absolute;
          top: 40px;
          left: 60px;
          font-size: 14px;
          color: rgba(136, 136, 136, 1);
          color: #bcbcbc;
          width: 150px;
          font-family: Alibaba PuHuiTi Xi;

          .son {
            margin-top: 20px;
            cursor: pointer;
          }

          .son:hover {
            color: #fff;
          }
        }
      }
    }

    .right {
      height: 100%;
      width: 15%;
      margin-left: 90px;
      img {
        width: 114px;
        height: 114px;
        // margin-left: 90px;
      }

      .tips {
        // margin-left: 90px;
        margin-top: 10px;
        color: rgba(151, 151, 151, 1);
        font-size: 12px;
        font-weight: 300;
        width: 180px;
        font-family: Alibaba PuHuiTi Xi;
      }

      .tongxun {
        margin-top: 30px;
        display: flex;

        .weixi {
          width: 42px;
          height: 42px;
          background-image: url("../assets/imgs/home/lianxi.png");
          background-size: 100% 100%;
          cursor: pointer;
          position: relative;

          .img {
            width: 76px;
            height: 76px;
            background-image: url("../assets/imgs/home/gongzonghao.png");
            background-size: 100% 100%;
            position: absolute;
            bottom: -90px;
            left: -20px;
            display: none;

            img {
              position: absolute;
              top: -10px;
              left: 34px;
            }
          }
        }

        .qq {
          width: 42px;
          height: 42px;
          margin-left: 20px;
          background-image: url("../assets/imgs/home/qq.png");
          background-size: 100% 100%;
          cursor: pointer;
          position: relative;

          .img {
            width: 76px;
            height: 76px;
            background-image: url("../assets/imgs/home/gongzonghao.png");
            background-size: 100% 100%;
            position: absolute;
            bottom: -90px;
            left: -20px;
            display: none;

            img {
              position: absolute;
              top: -10px;
              left: 34px;
            }
          }
        }

        .weixi:hover {
          background-image: url("../assets/imgs/home/lianxiactive.png");
        }

        .weixi:hover .img {
          display: block;
        }

        .qq:hover {
          background-image: url("../assets/imgs/home/qqactive.png");
        }

        .qq:hover .img {
          display: block;
        }
      }
    }
  }

  .from {
    position: absolute;
    left: 260px;
    bottom: 50px;
    color: rgba(151, 151, 151, 1);
    font-size: 12px;
    font-weight: 300;
    font-family: Alibaba PuHuiTi Zx;

    .link {
      position: absolute;
      width: 1920px;
      height: 40px;
      // background-color: red;
      top: -40px;
      left: 0;
      display: flex;
      align-items: center;
      color: #979797;

      .link-son {
        margin-right: 18px;
      }
    }
  }
}

a {
  color: rgba(151, 151, 151, 1);
}

a:active {
  color: rgba(151, 151, 151, 1);
}

a:visited {
  color: rgba(151, 151, 151, 1);
}

a:hover {
  color: #fff;
}
</style>
